@import "old/default.less";
@import "variables.less";



#header-right img {
    display: none;
}

html {

    body,
    p {
        .font_text;
    }

    .container-header .container,
    #header {
        background: unset;
    }

    #branding {
        background: none;
    }

    .container-header {}

    #header #branding {

        /* background: white url("../images/header.png") right center no-repeat;
        background-size: auto 240px;
        ;
        background-position: 15px 0px;
        background-size: cover;

        background-repeat: no-repeat;

        padding: 0px 15px;

        position: relative;
        top: -5px;
        margin-bottom: -5px;

        /*background-size: auto 304px;
        background-position: right -116px;*/
        #branding {
            padding: 0px;
            margin: 0px;
        }
    }

    html .menu li.active {}

    #navbar-secondary a:hover {
        color: white !important;
    }

    .navbar {
        min-height: 30px;
    }

    #branding {
        padding: 0px;
        margin-top: 0px;
    }

    .pane-apres-core-site-description {
        border: 0px;
    }

    .nav>li>a {
        position: relative;
        display: block;
        padding: 10px 24px;
    }

    .dropdown.open {
        z-index: 100;
    }

    .container-header {
        position: relative;
    }

    #navbar-collapse-primary {
        z-index: 10;
    }

    #navbar-primary {
        z-index: 20;
        min-height: 37px;
    }

    #header-right {

        z-index: auto;
    }

    @media (max-width: 800px) {
        #navbar-primary {
            z-index: 0;
        }

        #header-right {
            z-index: 1;
        }
    }



    .logo {
        border: 0px solid red;
        background: url("../v_2020/logoTL-posit-coul_CMJN_vecto.png") left center no-repeat;
        background-size: contain;
        background: none;
        margin-left: 0px;

        width: 100%;
        height: auto;
        padding: 0px;
        display: block;
        margin-top: 6px;

        .REMOVED::after {
            content: "pour une transition \a écologique, sociale et solidaire !";
            content: "";
            white-space: pre-wrap;
            margin-left: 350px-25px;
            display: block;
            color: @apresge_purple;
            .font_titre;
            font-size: 27px;
            padding-top: 30px;
        }

        .img_RESET_PUT_IN_TPL {
            display: none;
        }
    }

    .page-node-88950 .node-unpublished {
        background: none;

    }


    a {
        color: @apresge_darkgreen;
    }

    .container-header #search-form {
        background: @apresge_darkgreen;
    }

    #apres-search-global-form .search-autocomplete {}

    a:hover {
        color: @apresge_grey  !important;
        text-decoration: unset !important;
    }

    .menu a:hover,
    .titleLink a:hover {
        color: white !important;
    }

    .menu.navbar-nav.primary .dropdown-menu,
    .menu.navbar-nav.primary .dropdown-menu>.active,
    .nav.menu-block>li,
    .nav.menu-block>li.active {

        a,
        a:hover,
        a:active,
        a:focus,
        &>a:hover,
        &>a:focus,
        &>a:active {
            color: #544998 !important;
        }
    }

    .menu.navbar-nav.primary .dropdown-menu>.active,
    .nav.menu-block>li.active {

        a,

        a:active,
        a:focus,

        &>a:focus,
        &>a:active {
            color: #96A958 !important;
        }

        a:hover,
        &>a:hover {
            color: white !important;
        }

    }

    .nav.menu-block>li.active {

        a:hover,
        &>a:hover {
            color: #544998 !important;
        }
    }


    .container-header .menu,
    .pane-title,
    h2,
    h1,
    .nav.menu-block>li>a {
        .font_titre;
        color: @apresge_darkgreen  !important;
        font-size: 15px;
    }

    .__REMOVED0421_node--left .nav.menu-block>li>a:hover {
        background: @apresge_darkgreen;
        background: #62717E;


    }

    .navbar-default .navbar-nav>.active>a,
    .navbar-default .navbar-nav>.active>a:hover,
    .navbar-default .navbar-nav>.active>a:focus {
        background-color: @apresge_darkgreen;
    }

    #branding a {
        text-decoration: none;
    }

    @import "pages/home.less";

    @import "pages/mobile.less";
}

#navbar-secondary {
    background: white;
    margin-bottom: -7px;
}

.bandeau_violet #breves ul li .flexi_legend,
.new #breves ul li .flexi_legend {
    top: 0px;
    height: 300px;
    overflow: hidden;
    background-color: @apresge_purple  !important;
    bottom: auto;

    h2 {
        font-size: 44px !important;
    }

    .content p {
        font-size: 34px !important;
    }

    .content p a:hover {
        color: white;
    }
}

html #header li.active .dropdown-menu,
html #header li.active:hover .dropdown-menu {
    background: white !important;

    li,
    &>li,
    li:hover,
    a,
    &>a {
        background: white !important;
    }

    a:hover {
        background: #f5f5f5 !important;

    }
}

@import "pages/node.less";

.flexi_legend {
    display: none !important;
}

@media (max-width: 800px) {
    body.admin-menu {
        margin-top: 37px !important;
    }
}


@import "correction_032021.less";
