@apresge_grey: #62717E;
@titlemailcolor: black;
@normalcolor: @apresge_grey;
@apresge_red: #E9484A;
@apresge_darkgreen: #96A958;
@apresge_green: #83C7AD;
@apresge_purple: #544998;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto+Condensed:ital,wght@0,300;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto+Condensed:ital,wght@0,300;1,300&display=swap');


.typo_apresge_grey {
    color: @apresge_grey;
}

.typo_titlemailcolor {
    color: @titlemailcolor;
}

.typo_apresge_red {
    color: @apresge_red;
}

.typo_apresge_darkgreen {
    color: @apresge_darkgreen;
}

.typo_apresge_darkgreen {
    color: @apresge_darkgreen;
}

.typo_apresge_green {
    color: @apresge_green;
}

.typo_apresge_purple {
    color: @apresge_purple;
}

.mce-container-body {
    .typo_apresge_grey {
        color: @apresge_grey  !important;
    }

    .typo_titlemailcolor {
        color: @titlemailcolor  !important;
    }

    .typo_apresge_red {
        color: @apresge_red  !important;
    }

    .typo_apresge_darkgreen {
        color: @apresge_darkgreen  !important;
    }

    .typo_apresge_darkgreen {
        color: @apresge_darkgreen  !important;
    }

    .typo_apresge_green {
        color: @apresge_green  !important;
    }

    .typo_apresge_purple {
        color: @apresge_purple  !important;
    }

}


.fullwidth {
    .main-container {
        width: 100%;
        padding: 15px;
    }
}

.font_text {
    font-family: 'Roboto Condensed', sans-serif !important;
}

.font_text_bold {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700;
}

.font_titre,
.titre1,
.titre2 {
    font-family: 'Poppins', sans-serif !important;
}
