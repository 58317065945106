@import "variables.less";

#navbar-secondary {
  background: #62717E;

  li,
  li a {
    color: white;
  }
}

#header-right {
  top: 0px;
}

.follow_icon {
  width: 28px;
  height: 28px;
}


body,
table,
table tr td {
  /*MARCfont-size: 15px !important;*/

  blockquote {
    border-left: 0px;
    font-style: italic;
  }

  .title,
  .read-more,
  .more-link,
  p,
  li {
    font-size: 15px !important;
  }

  h2 {
    color: @apresge_purple  !important;
  }

  h3 {
    .titre2;
    font-size: 18px;
    color: @apresge_green  !important;
  }

  h4 {
    .font_text_bold !important;
    font-weight: 700 !important;
    color: black !important;
  }

  h5 {
    .font_text_bold !important;
    ;
    font-size: 15px !important;
    color: @apresge_grey  !important;
  }

  h6 {
    .font_text_bold !important;
    font-size: 15px !important;
    color: @apresge_red  !important;

  }

  .gray_button {
    color: black;
    background: @apresge_green;
  }

  .gray_button_lined {
    color: white;
    background: @apresge_red;
  }

  .mce-container-body {

    .gray_button,
    .Boutonvert,
    .BoutonRouge,
    .gray_button_lined {
      padding: 3px 5px !important;
    }
  }

  .pagination>li>a,
  .pagination>li>span {

    color: @apresge_purple;

  }

  .collapse-text-fieldset .panel-title {
    .titre1;
    color: inherit;
  }

  .view-mode-card,
  .field-name-node-link {
    a {
      .titre2;
    }
  }
}

.expanded.active.menu-mlid-1185,
.expanded.menu-mlid-1185,
.active.menu-mlid-1185 {
  ul {
    display: block;
  }
}

.menu-mlid-1185 {
  ul {
    display: none;
  }
}

.nav.menu-block>li>a.active-trail:before {
  content: '';
  background-color: #96A958;
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 100%;
}

#header li.active .dropdown-menu>li:hover>a {

  color: #544998 !important;
  font-weight: bold;
}

.pane-menu-tree>.pane-title {
  /*Marc
  color: #930084 !important;*/
  color: #544998 !important;


}

@media screen and (max-width: 768px) {

  .pane-menu-tree.pane-menu-collapssible>.pane-title,
  .pane-menu-tree.pane-menu-collapssible .nav>li>a,
  .html .nav.menu-block>li.active a {
    color: #fff !important;
    font-weight: normal;
  }

  .pane-menu-tree.pane-menu-collapssible>.pane-title {
    font-size: 17px;
    border-bottom: 3px solid white;
  }

  .pane-menu-tree.pane-menu-collapssible>.pane-title:after {
    right: 27px;
  }
}

.bodyhasframe {
  .rownum0{
    z-index:1000;
  }
  .rownum1{
    z-index:00;
  }
  .field-name-field-video {

    max-width: 850px;

    iframe {
      max-width: 850px;
      width: 850px;
      margin-top: -120px;
    }
  }
}
.home--left,
.home--right,
.home--center{
  zoom:1.2;
  h2 a{
    font-size:14px!important;
  }
}