.front {

    .view-events,
    .view-news-ess,
    .view-jobs-offers {

        .node-event,
        .node-news,
        .node-job-offer {

            .field-name-apres-summary,
            .field-name-node-link {
                display: none;
            }
        }
    }
}

.container {
    border: 0px solid red;
}

a:hover {
    text-decoration: underline;
}

.pane-apres-core-site-description>.pane-content {
    .font_titre;
}

#breves ul li a {
    overflow-x: hidden;
}

.img_resp_big,
.img_resp_small {}

.pane-apres-core-site-description {
    background: @apresge_grey;
    color: white;
}



#breves ul li,
#breves ul li a:hover {
    .flexi_legend {

        *,
        *:hover {
            color: white;
            font-weight: normal;
            /*@apresge_red;*/
        }
    }
}

.flex-breves-direction-nav {
    a {
        color: @apresge_grey  !important;
    }

    a:hover {
        color: @apresge_darkgreen  !important;
    }
}

.container-header .menu {
    text-transform: none;
}

.navbar-default {
    background: @apresge_grey;

    a.dropdown-toggle {
        text-transform: lowercase;

        &::first-letter {
            text-transform: lowercase;
        }
    }

    .navbar-nav {
        &>li>a {
            color: white;
        }

        &>li>a:hover {
            background: @apresge_darkgreen;
            color: white !important;
        }
    }
}

.dropdown-menu {
    li.first {
        margin-top: 5px;
    }

    &>li>a:hover {}

    &>li>a {
        text-transform: none;
        color: @apresge_grey;
        font-weight: normal;
        background: none;
        .font_text;
    }


}

#header-right .dropdown-menu {
    &>li>a {

        font-weight: normal;
        color: #96A958;
    }



    li>a:hover,
    li>a:focus {
        text-decoration: none;
        color: #707070;
        background-color: #f5f5f5;
        width: auto;
        display: block !important;
    }
}


#breves ul li a {
    &:hover {
        color: unset;
        font-weight: bold;
    }
}

.panel-pane-stripped-title>.pane-title {
    a {
        color: white !important;
        padding-left: 10px;
        text-transform: lowercase;
    }

    &::before {
        display: none;
    }

    &::after {
        display: none;
    }
}

.pane-events-panel-pane-list>.pane-title {
    background: @apresge_purple;
}

.pane-news-ess-panel-pane-list>.pane-title {
    background: @apresge_red;

    img {
        margin-right: -15px;
    }
}

.pane-jobs-offers-panel-pane-list>.pane-title {
    background: @apresge_green;


}

.pane-views-panes>.pane-title:hover {

    background: @apresge_darkgreen;

}

#header-right {
    position: absolute;
    top: 5;
    right: 5px;
    float: right;
    bottom: 0px;

    .glyphicon {
        top: 0px;
        color: white;
    }

    .dropdown-header {
        padding: 5px 12px;
        /*font-size: 15px !important;
        color: @apresge_darkgreen  !important;*/
        .titre1;
        color: @apresge_purple;
    }

    &>.dropdown {
        margin: 0px;
        padding: 0px;
    }

    .btn,
    &>.dropdown a,
    &>#search-form-toggle {
        line-height: 28px;
        margin-left: 5px;
        margin-right: 5px;
        display: inline-block;
        top: 0px;
        position: relative;
    }

    &>.dropdown {
        margin-right: 10px;
        margin-left: 0px;
    }

    .glyphicon-user:before {
        color: white;
    }

    .btn,
    .btn.btn-follow,
    .btn-follow:first-child {
        margin: 0px 2px 0px 2px;
        display: inline-block;
        float: none;
    }

    .btn.btn-follow {
        margin: 0px 2px 0px 2px;
    }

    .btn-follow:first-child {
        margin-left: 20px;
    }


    .btn {
        box-sizing: border-box;
        border: 0px solid transparent;
    }

    button,
    button:hover {

        &,
        a,
        span {
            margin: 0px;
            padding: 0px;
        }
    }

    .btn {
        background: @apresge_darkgreen;
    }




    .btn:hover {
        //border: 2px solid #62717E;

        img {
            width: 26px;
            height: 26px;
        }
    }

    img {
        display: block;
    }

    //&#XF007;
    * {
        border: 0px;
        border-radius: 0;
    }

    .btn-primary,
    .btn {
        background: #62717E;
        width: 28px;
        height: 28px;
        overflow: hidden;
    }

    .btn.btn-follow {
        background: none;

        i {
            display: none;
        }
    }

    &>div {
        max-width: 33px;


    }

    .btn-primary {
        padding: 0px;
        display: block;
        height: 28px;

        span {
            line-height: 28px;
        }
    }

    button.btn,
    div.dropdown {
        width: 28px;
        height: 28px;
        padding: 0px;
        position: initial;

        span {}
    }

    .form-actions button.btn {
        width: 90%;
    }

}

#navbar-secondary .nav .first a {
    background: @apresge_grey;
}

#header {

    .menu li.active:hover,
    .menu li.active:hover a,
    .menu li.active a:hover,
    .menu li:hover,
    .menu__ li a:hover {
        background: @apresge_darkgreen ;
    }

    .menu li.active {
        background: @apresge_grey;
    }
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open>.dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open>.dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open>.dropdown-toggle.btn-primary.focus {
    background: @apresge_grey;
}

.btn-default {
    color: #fff;
    background-color: @apresge_darkgreen;
    border-color: transparent;
}
