body.view_revisions0 #edit-revision-information {
  display: none;
}

.fa-facebook::before,
.fa-linkedin::before {
  content: '';
}

.fa-facebook {}

.fa-linkedin {}

.footer a.btn-follow {
  background: none !important;
  border: 0px !important;
  padding: 0px !important;
}

.fa-facebook img,
.fa-linkedin img {
  width: 30px;
  height: 30px;
}

.img_resp_small {
  display: none;
}
