.view-mode-list {
    &.node-event {
        .field-name-field-image {
            float: left;
            width: 48px;
            height: auto;
            margin-right: 20px;
        }

        .field-name-field-date-repeat {
            padding-top: 0px;
            margin-top: 0px;
        }

        .field-name-title {
            display: block;
        }
    }



}


html .front .pane-views-panes .title-node a {
    color: @apresge_grey;
    font-weight: normal;
    .font_text;
}

html .front .pane-views-panes {

    a,
    a:hover,
    a:visited,
    a:active {
        color: @apresge_darkgreen  !important;


    }

    .titleLink {

        a,
        a:hover,
        a:visited,
        a:active {
            color: white !important;
        }
    }



}
