// @prefix mst
// @descrip
@screen-desktop: 990px;

.pane-block-1 {
  margin-bottom: 20px;
}

#breves ul li .flexi_legend,
.home--aside .pane-block-1 .img_resp_small__ {
  display: block;
  transform: scale(1);
  zoom: 1;
  -webkit-transform: scale(1);
  max-width: calc(100% - 34px);
  width: calc(100% - 34px);
  width: 90%;
  max-width: 90%;
  margin: 0px;
  padding: 20px;
  margin: 0px auto;
  margin-left: 5%;

  h2 {
    .font_titre;

    a:hover {
      color: unset;
      text-decoration: underline;
    }
  }

  h2:hover {
    color: white;
  }



  a:hover h2 {}
}

.pane-apres-core-site-description {
  border: 0px important !;
}

@media (max-width: 800px) {
  .pane-block-1 {
    display: block;
    //max-height: 300px;
    /*set an height */
    overflow: hidden;
    /* crops/cut off */
    //line-height: 300px;
  }

  .panel-home {

    .col-sm-3,
    .view-events .field-name-field-image {
      width: 48% !important;
      float: left;



      display: block;
      max-height: 110px;
      /*set an height */
      overflow: hidden;
      /* crops/cut off */
      line-height: 110px;
    }

  }
}

#breves ul:after {
  content: " +";
  border: 10px solid red;
  position: absolute;
  right: 50px;
  bottom: 30px;
  color: white;
  font-size: 100px;
  font-weight: bold;
  display: none;
}

@media (max-width: @screen-desktop) {

  .img_resp_big {
    display: none !important;
  }

  .img_resp_small {
    display: block !important;
    height: auto;
    margin-bottom: 20px;
    padding: 0px;
    margin: 0px;
    width: 100%;
  }

  #breves__ ul li a {
    max-width: 100% !important;
    width: 100% !important;
    display: block;

  }

  #breves ul li .flexi_legend {
    max-width: 112% !important;
    width: 112% !important;
    margin: 0px -6%;
    display: block;
    bottom: auto;
    top: -30px;
  }

}


@media (min-width: @screen-desktop + 10) {
  .img_resp_small {
    display: none !important;
  }

  .img_resp_big {
    display: block !important;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 0px;
  }
}
